export const chartData = {
  line: [
    { days: "0-30 days", totalSales: 2994999, salesPercentage: 65 },
    { days: "31-60 days", totalSales: 133680, salesPercentage: 20 },
    { days: "61-90 days", totalSales: 62176, salesPercentage: 8 },
    { days: "91-365 days", totalSales: 35149, salesPercentage: 5 },
    { days: "Year 2+", totalSales: 28545, salesPercentage: 2 },
  ],
  bar: [
    { days: "Q1", totalSales: 1500000, salesPercentage: 45 },
    { days: "Q2", totalSales: 2100000, salesPercentage: 55 },
    { days: "Q3", totalSales: 1800000, salesPercentage: 48 },
    { days: "Q4", totalSales: 2400000, salesPercentage: 62 },
  ],
  pie: [
    { days: "Product A", totalSales: 800000, salesPercentage: 30 },
    { days: "Product B", totalSales: 600000, salesPercentage: 25 },
    { days: "Product C", totalSales: 400000, salesPercentage: 20 },
    { days: "Others", totalSales: 200000, salesPercentage: 25 },
  ],
  area: [
    { days: "Jan", totalSales: 500000, salesPercentage: 40 },
    { days: "Feb", totalSales: 700000, salesPercentage: 45 },
    { days: "Mar", totalSales: 900000, salesPercentage: 50 },
    { days: "Apr", totalSales: 1100000, salesPercentage: 55 },
  ],
};

export const filterOptions = {
  cities: ["New York", "London", "Tokyo", "Paris", "Singapore"],
  years: ["2020", "2021", "2022", "2023", "2024"],
  trades: ["Forex", "Stocks", "Crypto", "Commodities", "Bonds"],
};
