import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateCampaignRequest } from "../../../../../api/createCampaign/types";
import { AppDispatch, AppThunk } from "../../../../../app/store";
import { createCampaign } from "../../../../../api/createCampaign/createCampaignApi";
import {
  CampaignStatus,
  FetchCampaignStatusesResponse,
} from "../../../../../api/fetchCampaignStatuses/types";
import { fetchCampaignStatuses } from "../../../../../api/fetchCampaignStatuses/fetchCampaignStatusesApi";

interface CreateCampaignState {
  statuses: CampaignStatus[];
  loadingCreate: boolean;
  loadingStatuses: boolean;
  errorCreate: string | null;
  errorStatuses: string | null;
}

const initialState: CreateCampaignState = {
  statuses: [],
  loadingCreate: false,
  loadingStatuses: false,
  errorCreate: null,
  errorStatuses: null,
};

const createCampaignSlice = createSlice({
  name: "createCampaign",
  initialState,
  reducers: {
    setLoadingCreate: (state, action: PayloadAction<boolean>) => {
      state.loadingCreate = action.payload;
    },
    setLoadingStatuses: (state, action: PayloadAction<boolean>) => {
      state.loadingStatuses = action.payload;
    },
    setErrorCreate: (state, action: PayloadAction<string | null>) => {
      state.errorCreate = action.payload;
    },
    setErrorStatuses: (state, action: PayloadAction<string | null>) => {
      state.errorStatuses = action.payload;
    },
    setCampaignStatuses: (state, action: PayloadAction<CampaignStatus[]>) => {
      state.statuses = action.payload;
    },
    clearCreateCampaignData: (state) => {
      state.errorCreate = null;
    },
  },
});

export const {
  setLoadingCreate,
  setLoadingStatuses,
  setErrorCreate,
  setErrorStatuses,
  setCampaignStatuses,
  clearCreateCampaignData,
} = createCampaignSlice.actions;

export const createCampaignAction =
  (requestData: CreateCampaignRequest, callback?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoadingCreate(true));
    try {
      await createCampaign(requestData);
      dispatch(setErrorCreate(null));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setErrorCreate(
          error instanceof Error ? error.message : "Failed to create campaign",
        ),
      );
    } finally {
      dispatch(setLoadingCreate(false));
    }
  };

export const fetchCampaignStatusesAction =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoadingStatuses(true));
    try {
      const response: FetchCampaignStatusesResponse =
        await fetchCampaignStatuses();
      dispatch(setCampaignStatuses(response.data));
      dispatch(setErrorStatuses(null));
    } catch (error) {
      dispatch(
        setErrorStatuses(
          error instanceof Error
            ? error.message
            : "Failed to fetch campaign statuses",
        ),
      );
    } finally {
      dispatch(setLoadingStatuses(false));
    }
  };

export default createCampaignSlice.reducer;
