import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { StochasticCustomer } from "../../../../../../api/fetchStochasticCustomers/types";

export function createDoNotMailColumns(): ColumnDef<StochasticCustomer>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "External ID",
      accessorKey: "external_id",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div>
          <button onClick={() => alert(`Customer ID: ${row.original.id}`)}>
            View
          </button>
        </div>
      ),
      enableSorting: false,
    },
  ];
}
