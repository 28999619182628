import {
  UsersIcon,
  EyeIcon,
  ChartBarIcon,
  MegaphoneIcon,
} from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";

const stochasticNavigation: NavigationItem[] = [
  {
    name: "Prospects",
    href: "/stochastic/prospects",
    icon: EyeIcon,
    current: false,
    internalName: "prospects",
    permissions: ["CAN_MANAGE_PROSPECTS"],
  },
  {
    name: "Customers",
    href: "/stochastic/customers",
    icon: UsersIcon,
    current: false,
    internalName: "customers",
    permissions: ["CAN_MANAGE_CUSTOMERS"],
  },
  {
    name: "Do Not Mail",
    href: "/stochastic/do-not-mail",
    icon: EyeIcon,
    current: false,
    internalName: "doNotMail",
    permissions: ["CAN_MANAGE_DO_NOT_MAIL_LIST"],
  },
  {
    name: "Dashboards",
    href: "#",
    icon: ChartBarIcon,
    current: false,
    internalName: "dashboards",
    permissions: [],
    children: [
      {
        name: "Dashboard",
        href: "/stochastic/dashboard",
        icon: UsersIcon,
        current: false,
        internalName: "dashboard",
        permissions: [],
      },
    ],
  },
  {
    name: "Campaign Management",
    href: "/stochastic/campaigns",
    icon: MegaphoneIcon,
    current: false,
    internalName: "campaigns",
    permissions: ["CAN_MANAGE_CAMPAIGNS"],
  },
];

export default stochasticNavigation;
