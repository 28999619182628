import React from "react";
import { Table as ReactTableInstance } from "@tanstack/react-table";

interface DataTablePaginationProps<TData extends object> {
  table: ReactTableInstance<TData>;
}

function DataTablePagination<TData extends object>({
  table,
}: DataTablePaginationProps<TData>) {
  const renderPaginationButtons = () => {
    const totalPages = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex;
    const pageNumbers = [];

    const startPage = Math.max(0, currentPage - 2);
    const endPage = Math.min(startPage + 4, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          className={`px-4 py-2 rounded-md focus:outline-none ${
            i === currentPage
              ? "bg-primary dark:bg-primary text-white"
              : "bg-secondary text-white hover:bg-secondary-dark"
          }`}
          key={i}
          onClick={() => table.setPageIndex(i)}
        >
          {i + 1}
        </button>,
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex justify-between items-center text-sm">
      <button
        aria-label="Previous page"
        className="mx-2 px-4 py-2 rounded bg-secondary text-white hover:bg-secondary-dark disabled:bg-gray-300 dark:bg-primary dark:hover:bg-primary-dark focus:outline-none"
        disabled={!table.getCanPreviousPage()}
        onClick={() => table.previousPage()}
      >
        ←
      </button>
      <div className="flex space-x-2">{renderPaginationButtons()}</div>
      <button
        aria-label="Next page"
        className="mx-2 px-4 py-2 rounded bg-secondary text-white hover:bg-secondary-dark disabled:bg-gray-300 dark:bg-primary dark:hover:bg-primary-dark focus:outline-none"
        disabled={!table.getCanNextPage()}
        onClick={() => table.nextPage()}
      >
        →
      </button>
    </div>
  );
}

export default DataTablePagination;
