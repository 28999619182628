import React, { useCallback, useMemo } from "react";
import { useUsers } from "../../hooks/useUsers";
import { createUsersColumns } from "../UserColumns/UserColumns";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import UserListFilters from "../UserListFilters/UserListFilters";
import DataTable from "../../../../../../components/Datatable/Datatable";
import { useNavigate } from "react-router-dom";
import { Row } from "@tanstack/react-table";
import { Button } from "@/components/Button/Button";
import { User } from "@/modules/hub/features/UserManagement/slices/usersSlice";

const UserList: React.FC = () => {
  const {
    users,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleFilterChange,
    filters,
  } = useUsers();

  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (row: Row<User>) => {
      navigate(`/hub/users/${row.original.employeeUuid}/edit`);
    },
    [navigate],
  );

  const handleEdit = useCallback(
    (uuid: string) => {
      navigate(`/hub/users/${uuid}/edit`);
    },
    [navigate],
  );

  const handleImpersonateUser = useCallback((uuid: string) => {
    localStorage.removeItem("selectedCompanyUuid");
    localStorage.setItem("impersonateUserUuid", uuid);
    console.log(`Switched to user with UUID: ${uuid}`);
    navigate(0);
  }, []);

  const columns = useMemo(
    () => createUsersColumns({ handleEdit, handleImpersonateUser }),
    [handleEdit, handleImpersonateUser],
  );

  return (
    <MainPageWrapper
      actions={
        <Button onClick={() => navigate("/hub/users/create")}>
          Create User
        </Button>
      }
      error={error}
      loading={loading}
      title="Users"
    >
      <UserListFilters
        filters={filters}
        onFilterChange={(filterKey: string, value: string) => {
          handleFilterChange(filterKey, value);
          handlePaginationChange({
            pageIndex: 0,
            pageSize: pagination.pageSize,
          });
        }}
      />

      <DataTable
        columnVisibility={columnVisibility}
        columns={columns}
        data={users}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onPaginationChange={handlePaginationChange}
        onRowClick={handleRowClick}
        onSortingChange={handleSortingChange}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        sorting={sorting}
      />
    </MainPageWrapper>
  );
};

export default UserList;
