// src/modules/stochastic/features/CampaignBatchManagement/components/CampaignBatchColumns/CampaignBatchColumns.ts

import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../../../../../components/catalyst-ui-kit/dropdown";
import { Batch } from "../../../../../../api/getCampaignBatches/types";

interface CreateCampaignBatchesColumnsProps {
  handleViewProspects: (id: number) => void;
  handleDownloadCsv: (id: number) => void;
}

export function createCampaignBatchesColumns({
  handleViewProspects,
  handleDownloadCsv,
}: CreateCampaignBatchesColumnsProps): ColumnDef<Batch>[] {
  return [
    {
      header: "Batch ID",
      accessorKey: "id",
      cell: ({ row }) => (
        <div 
          className="cursor-pointer hover:text-blue-600"
          onClick={() => handleViewProspects(row.original.id)}
        >
          {row.original.id}
        </div>
      ),
    },
    {
      header: "Batch Name",
      accessorKey: "name",
      cell: ({ row }) => (
        <div
          className="cursor-pointer hover:text-blue-600"
          onClick={() => handleViewProspects(row.original.id)}
        >
          {row.original.name}
        </div>
      ),
    },
    {
      header: "Batch Status",
      accessorKey: "batchStatus",
      cell: ({ row }) => (
        <div
          className="cursor-pointer hover:text-blue-600"
          onClick={() => handleViewProspects(row.original.id)}
        >
          {row.original.batchStatus?.name
            ? row.original.batchStatus.name.charAt(0).toUpperCase() +
              row.original.batchStatus.name.slice(1)
            : "N/A"}
        </div>
      ),
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Dropdown>
          <DropdownButton className="p-1" plain>
            <EllipsisVerticalIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400" />
          </DropdownButton>
          <DropdownMenu>
            <DropdownItem onClick={() => handleViewProspects(row.original.id)}>
              View Prospects
            </DropdownItem>
            <DropdownItem onClick={() => handleDownloadCsv(row.original.id)}>
              Download CSV
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      enableSorting: false,
    },
  ];
}
