import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../../../../../components/catalyst-ui-kit/dropdown";
import { Campaign } from "../../../../../../api/fetchCompanyCampaigns/types";

interface CreateCampaignsColumnsProps {
  handleEdit: (id: number) => void;
  handleViewBatches: (id: number) => void;
  handleViewFiles: (id: number) => void;
}

export function createCampaignsColumns({
  handleEdit,
  handleViewBatches,
  handleViewFiles,
}: CreateCampaignsColumnsProps): ColumnDef<Campaign>[] {
  return [
    {
      header: "Campaign Name",
      accessorKey: "name",
    },
    {
      header: "Phone Number",
      accessorKey: "phoneNumber",
      cell: ({ row }) => row.original.phoneNumber || "N/A",
    },
    {
      header: "Start Date",
      accessorKey: "startDate",
      cell: ({ row }) => row.original.startDate || "N/A",
    },
    {
      header: "End Date",
      accessorKey: "endDate",
      cell: ({ row }) => row.original.endDate || "N/A",
    },
    {
      header: "Status",
      accessorKey: "campaignStatus",
      cell: ({ row }) =>
        row.original.campaignStatus?.name
          ? row.original.campaignStatus.name.charAt(0).toUpperCase() +
            row.original.campaignStatus.name.slice(1)
          : "N/A",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Dropdown>
          <DropdownButton className="p-1" plain>
            <EllipsisVerticalIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400" />
          </DropdownButton>
          <DropdownMenu>
            <DropdownItem onClick={() => handleEdit(row.original.id)}>
              Edit
            </DropdownItem>
            <DropdownItem onClick={() => handleViewBatches(row.original.id)}>
              View Batches
            </DropdownItem>
            <DropdownItem onClick={() => handleViewFiles(row.original.id)}>
              View Files
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ),
      enableSorting: false,
    },
  ];
}
