import React from "react";
import { Table as ReactTableInstance } from "@tanstack/react-table";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

interface PageSizeDropdownProps<TData extends object> {
  table: ReactTableInstance<TData>;
}

function PageSizeDropdown<TData extends object>({
  table,
}: PageSizeDropdownProps<TData>) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="flex items-center justify-center px-4 py-2 bg-secondary text-white rounded-md dark:bg-primary">
        Show {table.getState().pagination.pageSize}
        <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" />
      </MenuButton>
      <MenuItems className="absolute left-0 z-10 mt-2 w-40 bg-white dark:bg-gray-800 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-1">
        {[10, 20, 30, 40, 50].map((ps) => (
          <MenuItem key={ps}>
            {({ active }) => (
              <div
                className={`${active ? "bg-gray-100 dark:bg-gray-700" : ""} px-4 py-2 cursor-pointer`}
                onClick={() => table.setPageSize(ps)}
              >
                Show {ps}
              </div>
            )}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}

export default PageSizeDropdown;
