import React from "react";
import { Row, flexRender } from "@tanstack/react-table";
import { TableBody, TableCell, TableRow } from "../../../catalyst-ui-kit/table";

interface DataTableBodyProps<TData> {
  rows: Row<TData>[];
  onRowClick?: (row: Row<TData>) => void;
}

function DataTableBody<TData>({ rows, onRowClick }: DataTableBodyProps<TData>) {
  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow
          className={onRowClick ? "cursor-pointer hover:bg-gray-50" : ""}
          key={row.id}
          onClick={() => onRowClick?.(row)}
        >
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export default DataTableBody;
