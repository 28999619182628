import React from "react";
import AlphaChart from "../../../Dashboard/components/StochasticDashboard/StochasticDashboard";
import {
  chartData,
  customerSalesData,
  getYearlyChartData,
  topZipsData,
  zipData,
} from "../../data/Stochasticmockdata";

export default function StochasticDashboard() {
  const yearlyData = getYearlyChartData();

  return (
    <div className="bg-white dark:bg-secondary-dark min-h-screen p-8 transition-colors duration-200">
      <div className="max-w-7xl mx-auto space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="dark:text-light font-bold text-3xl text-fontColor">
            Stochastic Dashboard
          </h1>
        </div>

        <AlphaChart initialData={topZipsData} type="topZips" />
        <AlphaChart initialData={customerSalesData} type="customer" />
        <AlphaChart initialData={yearlyData} type="yearly" />
        <AlphaChart initialData={zipData} type="zip" />

        <div className="gap-8 grid grid-cols-1 lg:grid-cols-2">
          <AlphaChart initialData={chartData.line} type="line" />
          <AlphaChart initialData={chartData.bar} type="bar" />
          <AlphaChart initialData={chartData.pie} type="pie" />
          <AlphaChart initialData={chartData.area} type="area" />
        </div>
      </div>
    </div>
  );
}
