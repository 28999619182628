import React, { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import {
  XMarkIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logoMain from "../../assets/images/logo-main.svg";
import { NavigationItem } from "../../utils/navigationConfig/types";
import { useSelector } from "react-redux";
import { getSectionTitle } from "../../utils/navigationConfig/navigationConfig";
import { selectActiveSessionCompanyName } from "../../modules/hub/features/UserAppSettings/selectors/userAppSettingsSelectors";
import logoMainWhite from "../../assets/images/logo-main-white.svg";
import { useTheme } from "../../context/ThemeContext";
import clsx from "clsx";

interface MobileSidebarDialogProps {
  section: string | null;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  navigationWide: NavigationItem[];
}

const MobileSidebarDialog: React.FC<MobileSidebarDialogProps> = ({
  section,
  sidebarOpen,
  setSidebarOpen,
  navigationWide,
}) => {
  const [openDropdowns, setOpenDropdowns] = useState<{
    [key: string]: boolean;
  }>({});
  const sectionTitle = getSectionTitle(section);
  const activeCompanyName = useSelector(selectActiveSessionCompanyName);
  const { theme } = useTheme();

  const toggleDropdown = (name: string) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleNavigationClick = () => {
    setSidebarOpen(false);
  };

  return (
    <Dialog
      className="relative z-20 lg:hidden"
      onClose={setSidebarOpen}
      open={sidebarOpen}
    >
      <DialogBackdrop className="fixed inset-0 bg-gray-900/80 transition-opacity" />
      <div className="fixed inset-0 flex">
        <TransitionChild
          as="div"
          className="relative flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out translate-x-0 data-[closed]:-translate-x-full"
          enter="transform transition ease-in-out duration-300"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <DialogPanel className="relative flex h-full w-full flex-col bg-white shadow-xl">
            <div className="flex-1 flex flex-col overflow-y-auto bg-white dark:bg-secondary px-6 pb-4">
              <div className="flex justify-between mt-4">
                <img
                  alt="Your Company"
                  className="h-8 w-auto self-start"
                  src={theme === "dark" ? logoMainWhite : logoMain}
                />
                <button onClick={() => setSidebarOpen(false)} type="button">
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-gray-400"
                  />
                </button>
              </div>

              <div className="mt-4 flex flex-col">
                <span className="text-2xl font-semibold text-gray-900 dark:text-white">
                  {sectionTitle}
                </span>
                {activeCompanyName && (
                  <span className="text-sm text-gray-500 mt-1 dark:text-white">
                    {activeCompanyName}
                  </span>
                )}
              </div>

              <nav className="mt-8 flex-1">
                <ul className="flex flex-col gap-y-4" role="list">
                  {navigationWide.map((item) => (
                    <li key={item.name}>
                      <div>
                        {item.children ? (
                          <div
                            className={clsx(
                              item.current
                                ? "bg-gray-50 text-indigo-600 dark:text-white dark:bg-primary"
                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600 dark:hover:bg-primary dark:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer",
                            )}
                            onClick={() => toggleDropdown(item.name)}
                          >
                            <item.icon
                              aria-hidden="true"
                              className={clsx(
                                item.current
                                  ? "text-indigo-600 dark:text-white"
                                  : "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-white",
                                "h-6 w-6 shrink-0",
                              )}
                            />
                            {item.name}
                            <span className="ml-auto flex items-center">
                              {openDropdowns[item.name] ? (
                                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                              ) : (
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                              )}
                            </span>
                          </div>
                        ) : (
                          <Link
                            className={clsx(
                              item.current
                                ? "bg-gray-50 text-indigo-600 dark:text-white dark:bg-primary"
                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600 dark:hover:bg-primary dark:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                            )}
                            onClick={handleNavigationClick}
                            to={item.href}
                          >
                            <item.icon
                              aria-hidden="true"
                              className={clsx(
                                item.current
                                  ? "text-indigo-600 dark:text-white"
                                  : "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-white",
                                "h-6 w-6 shrink-0",
                              )}
                            />
                            {item.name}
                          </Link>
                        )}

                        {item.children && openDropdowns[item.name] && (
                          <ul className="ml-6 mt-1 space-y-1">
                            {item.children.map((subItem) => (
                              <li key={subItem.name}>
                                <Link
                                  className={clsx(
                                    subItem.current
                                      ? "bg-gray-50 text-indigo-600 dark:text-white dark:bg-primary"
                                      : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600 dark:hover:bg-primary dark:text-white",
                                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                  )}
                                  onClick={handleNavigationClick}
                                  to={subItem.href}
                                >
                                  <subItem.icon
                                    aria-hidden="true"
                                    className={clsx(
                                      subItem.current
                                        ? "text-indigo-600 dark:text-white"
                                        : "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-white",
                                      "h-5 w-5 shrink-0",
                                    )}
                                  />
                                  {subItem.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  );
};

export default MobileSidebarDialog;
