import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  SortingState,
  ColumnDef,
  PaginationState,
  OnChangeFn,
  VisibilityState,
  Row,
} from "@tanstack/react-table";
import { Table } from "../catalyst-ui-kit/table";
import DataTableHeader from "./components/DataTableHeader/DataTableHeader";
import DataTableBody from "./components/DataTableBody/DataTableBody";
import DataTablePagination from "./components/DataTablePagination/DataTablePagination";
import ColumnVisibilityDropdown from "./components/ColumnVisibilityDropdown/ColumnVisibilityDropdown";
import PageSizeDropdown from "./components/PageSizeDropdown/PageSizeDropdown";

interface DataTableProps<TData extends object> {
  data: TData[];
  columns: ColumnDef<TData>[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  onPaginationChange: OnChangeFn<PaginationState>;
  columnVisibility: VisibilityState;
  onColumnVisibilityChange: OnChangeFn<VisibilityState>;
  onRowClick?: (row: Row<TData>) => void;
}

function DataTable<TData extends object>({
  data,
  columns,
  pageCount,
  pageIndex,
  pageSize,
  sorting,
  onSortingChange,
  onPaginationChange,
  columnVisibility,
  onColumnVisibilityChange,
  onRowClick,
}: DataTableProps<TData>) {
  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      pagination: { pageIndex, pageSize },
      sorting,
      columnVisibility,
    },
    onPaginationChange,
    onSortingChange,
    onColumnVisibilityChange,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
  });

  return (
    <div>
      <div className="mb-4 flex justify-end">
        <ColumnVisibilityDropdown table={table} />
      </div>
      <div>
        <Table>
          <DataTableHeader<TData> headerGroups={table.getHeaderGroups()} />
          <DataTableBody<TData>
            onRowClick={onRowClick}
            rows={table.getRowModel().rows}
          />
        </Table>
      </div>
      <div className="mt-10 flex flex-col w-full md:flex-row md:items-center">
        {/* Pagination first in the DOM:
            - On mobile, order-1 for pagination so it appears at the top (first)
            - w-full on mobile to span the entire width
            - md:order-2 on larger screens to position it after page size dropdown
            - md:w-auto on larger screens so it doesn't force full width */}
        <div className="order-1 w-full md:order-2 md:w-auto flex flex-1 justify-center mb-4 md:mb-0">
          <DataTablePagination<TData> table={table} />
        </div>

        {/* Page size dropdown second in the DOM:
            - On mobile, order-2 so it appears below the pagination
            - w-full on mobile to match the pagination width
            - md:order-1 to move it to the left side on desktop
            - md:w-auto on larger screens so it doesn't take full width */}
        <div className="order-2 w-full md:order-1 md:w-auto flex justify-start">
          <PageSizeDropdown<TData> table={table} />
        </div>
      </div>
    </div>
  );
}

export default DataTable;
