import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  StochasticCustomer,
  FetchStochasticCustomersRequest,
  FetchStochasticCustomersResponse,
} from "../../../../../api/fetchStochasticCustomers/types";
import { AppDispatch, AppThunk } from "../../../../../app/store";
import { fetchStochasticCustomers } from "../../../../../api/fetchStochasticCustomers/fetchStochasticCustomersApi";

interface DoNotMailState {
  doNotMailCustomers: StochasticCustomer[];
  totalCount: number;
  loading: boolean;
  error: string | null;
}

const initialState: DoNotMailState = {
  doNotMailCustomers: [],
  totalCount: 0,
  loading: false,
  error: null,
};

const doNotMailSlice = createSlice({
  name: "doNotMail",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setDoNotMailCustomersData: (
      state,
      action: PayloadAction<{
        customers: StochasticCustomer[];
        totalCount: number;
      }>,
    ) => {
      state.doNotMailCustomers = action.payload.customers;
      state.totalCount = action.payload.totalCount;
    },
  },
});

export const { setLoading, setError, setDoNotMailCustomersData } =
  doNotMailSlice.actions;

export const fetchDoNotMailCustomersAction =
  (requestData: Omit<FetchStochasticCustomersRequest, "filters">): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: FetchStochasticCustomersResponse =
        await fetchStochasticCustomers({
          ...requestData,
          filters: { doNotMail: true },
        } as FetchStochasticCustomersRequest);
      dispatch(
        setDoNotMailCustomersData({
          customers: response.data,
          totalCount: response.meta?.totalCount || 0,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Failed to fetch Do Not Mail customers"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export default doNotMailSlice.reducer;
