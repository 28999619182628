import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersAction, setColumnVisibility } from "../slices/usersSlice";
import {
  SortingState,
  PaginationState,
  OnChangeFn,
  VisibilityState,
} from "@tanstack/react-table";
import { RootState } from "../../../../../app/rootReducer";
import { FetchUsersRequest } from "../../../../../api/fetchUsers/types";

export function useUsers() {
  const dispatch = useDispatch();
  const { users, totalCount, loading, error, columnVisibility } = useSelector(
    (state: RootState) => state.users,
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const [filters, setFilters] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
    salesforceId?: string;
  }>({});

  const requestData = React.useMemo<FetchUsersRequest>(
    () => ({
      page: pagination.pageIndex + 1,
      sortBy: sorting.length > 0 ? sorting[0].id : undefined,
      sortOrder:
        sorting.length > 0 ? (sorting[0].desc ? "DESC" : "ASC") : undefined,
      firstName: filters.firstName,
      lastName: filters.lastName,
      email: filters.email,
      salesforceId: filters.salesforceId,
      pageSize: 10,
    }),
    [pagination.pageIndex, sorting, filters],
  );

  useEffect(() => {
    dispatch(fetchUsersAction(requestData));
  }, [dispatch, pagination.pageIndex, sorting, filters]);

  const handlePaginationChange: OnChangeFn<PaginationState> = (
    updaterOrValue,
  ) => {
    setPagination((old) =>
      typeof updaterOrValue === "function"
        ? updaterOrValue(old)
        : updaterOrValue,
    );
  };

  const handleSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
    setSorting((old) =>
      typeof updaterOrValue === "function"
        ? updaterOrValue(old)
        : updaterOrValue,
    );
  };

  const handleColumnVisibilityChange: OnChangeFn<VisibilityState> = (
    updaterOrValue,
  ) => {
    const newVisibility =
      typeof updaterOrValue === "function"
        ? updaterOrValue(columnVisibility)
        : updaterOrValue;

    if (Object.keys(newVisibility).length > 0) {
      dispatch(setColumnVisibility(newVisibility));
    }
  };

  const handleFilterChange = (filterKey: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: value,
    }));
  };

  return {
    users,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    filters,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleFilterChange,
  };
}
