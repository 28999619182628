import React from "react";
import { useLocation, Link } from "react-router-dom";
import { getSkinnyNavigation } from "../../utils/navigationConfig/navigationConfig";
import logoSmall from "../../assets/images/logo-small.svg";
import logoSmallWhite from "../../assets/images/logo-small-white.svg";
import { NavigationItem } from "../../utils/navigationConfig/types";
import { useTheme } from "../../context/ThemeContext";
import clsx from "clsx";

const SkinnySidebar: React.FC = () => {
  const { theme } = useTheme();
  const location = useLocation();
  const navigationSkinny: NavigationItem[] = getSkinnyNavigation(
    location.pathname,
  );

  return (
    <>
      {/* Desktop Sidebar */}
      <div className="hidden fixed lg:flex flex-col w-20 bg-white border-r border-gray-200 min-h-screen z-30 dark:bg-secondary">
        <div className="flex h-16 shrink-0 items-center justify-center">
          <img
            alt="Your Company"
            className="h-8 w-auto"
            src={theme === "dark" ? logoSmallWhite : logoSmall}
          />
        </div>
        <nav className="mt-8 flex-1 flex flex-col items-center">
          <ul className="space-y-1" role="list">
            {navigationSkinny.map((item) => (
              <li key={item.name}>
                <Link
                  className={clsx(
                    item.current
                      ? "bg-secondary text-white dark:text-white dark:bg-primary"
                      : "text-gray-400 hover:bg-secondary hover:text-white dark:hover:bg-primary",
                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                  )}
                  title={item.name}
                  to={item.href}
                >
                  <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                  <span className="sr-only">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Mobile Bottom Navigation */}
      <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 dark:bg-secondary z-10">
        <nav className="flex py-3">
          {navigationSkinny.map((item, index) => (
            <Link
              className={clsx(
                item.current
                  ? "text-primary dark:text-white"
                  : "text-gray-400 hover:text-primary dark:hover:text-white",
                "flex flex-col items-center justify-center flex-1 text-center",
                index !== 0 && "border-l border-gray-300",
              )}
              key={item.name}
              to={item.href}
            >
              <item.icon className="h-6 w-6" />
              <span className="text-xs mt-1">{item.name}</span>
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
};

export default SkinnySidebar;
