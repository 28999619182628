import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getSectionTitle } from "../../utils/navigationConfig/navigationConfig";
import { selectActiveSessionCompanyName } from "../../modules/hub/features/UserAppSettings/selectors/userAppSettingsSelectors";
import { NavigationItem } from "../../utils/navigationConfig/types";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useTheme } from "../../context/ThemeContext";
import logoMain from "../../assets/images/logo-main.svg";
import logoMainWhite from "../../assets/images/logo-main-white.svg";
import { Link } from "react-router-dom";
import clsx from "clsx";

interface WideSidebarProps {
  section: string | null;
  navigation: NavigationItem[];
}

const WideSidebar: React.FC<WideSidebarProps> = ({ section, navigation }) => {
  const sectionTitle = getSectionTitle(section);
  const activeCompanyName = useSelector(selectActiveSessionCompanyName);
  const { theme } = useTheme();
  const [openDropdowns, setOpenDropdowns] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleDropdown = (name: string) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  return (
    <div
      className="hidden bg-white dark:bg-secondary border-r border-gray-200 lg:flex lg:flex-shrink-0 pl-20 min-h-screen"
      style={{ width: "400px" }}
    >
      <div className="flex flex-col w-72 px-6 pb-4 fixed">
        {/* Logo, Section Title, and Company Name */}
        <div className="flex flex-col mt-4 mb-4">
          <img
            alt="Your Company"
            className="h-8 w-auto mb-4 self-start"
            src={theme === "dark" ? logoMainWhite : logoMain}
          />{" "}
          {/* Left-align the logo */}
          <div className="flex flex-col">
            <span className="text-2xl font-semibold text-gray-900 dark:text-white">
              {sectionTitle}
            </span>
            {activeCompanyName && (
              <span className="text-sm text-gray-500 mt-1 dark:text-white">
                {activeCompanyName}
              </span>
            )}
          </div>
        </div>

        {/* Navigation */}
        <nav className="mt-4 flex-1">
          <ul className="flex flex-col gap-y-4" role="list">
            {" "}
            {/* Reduced gap between items */}
            {navigation.map((item) => (
              <li key={item.name}>
                <div>
                  {item.children ? (
                    <div
                      className={clsx(
                        item.current
                          ? "bg-gray-50 text-indigo-600 dark:text-white dark:bg-primary"
                          : "text-gray-700 dark:hover:bg-primary hover:bg-gray-50 hover:text-indigo-600 dark:text-white",
                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer",
                      )}
                      onClick={() => toggleDropdown(item.name)}
                    >
                      <item.icon
                        aria-hidden="true"
                        className={clsx(
                          item.current
                            ? "text-indigo-600 dark:text-white"
                            : "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-white",
                          "h-6 w-6 shrink-0",
                        )}
                      />
                      {item.name}
                      <span className="ml-auto flex items-center">
                        {openDropdowns[item.name] ? (
                          <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                        ) : (
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                        )}
                      </span>
                    </div>
                  ) : (
                    <Link
                      className={clsx(
                        item.current
                          ? "bg-gray-50 text-indigo-600 dark:bg-primary dark:text-white"
                          : "text-gray-700 dark:hover:bg-primary hover:bg-gray-50 hover:text-indigo-600 dark:text-white",
                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                      )}
                      to={item.href}
                    >
                      <item.icon
                        aria-hidden="true"
                        className={clsx(
                          item.current
                            ? "text-indigo-600 dark:text-white"
                            : "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-white",
                          "h-6 w-6 shrink-0",
                        )}
                      />
                      {item.name}
                    </Link>
                  )}

                  {/* Render sub-navigation if it has children and is open */}
                  {item.children && openDropdowns[item.name] && (
                    <ul className="ml-6 mt-1 space-y-1">
                      {item.children.map((subItem) => (
                        <li key={subItem.name}>
                          <Link
                            className={clsx(
                              subItem.current
                                ? "bg-gray-50 text-indigo-600 dark:bg-primary dark:text-white"
                                : "text-gray-700 dark:hover:bg-primary hover:bg-gray-50 hover:text-indigo-600 dark:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                            )}
                            to={subItem.href}
                          >
                            <subItem.icon
                              aria-hidden="true"
                              className={clsx(
                                subItem.current
                                  ? "text-indigo-600 dark:text-white"
                                  : "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-white",
                                "h-5 w-5 shrink-0",
                              )}
                            />
                            {subItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default WideSidebar;
