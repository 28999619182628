import React from "react";
import { useLocation, Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/20/solid";
import { breadcrumbNameMap } from "../../utils/breadcrumbConfig/breadcrumbConfig";

const formatBreadcrumb = (str: string) => {
  const formatted = str
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
  return formatted.length > 10 ? formatted.slice(0, 7) + "..." : formatted;
};

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <nav
      aria-label="Breadcrumb"
      className="flex border-b border-gray-200 bg-white"
    >
      <ol
        className="flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8 flex-wrap"
        role="list"
      >
        <li className="flex">
          <div className="flex items-center py-1">
            <Link className="text-gray-400 hover:text-gray-500" to="/">
              <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;

          const breadcrumb = breadcrumbNameMap[to];
          const breadcrumbName = breadcrumb
            ? breadcrumb.name
            : formatBreadcrumb(value);
          const isClickable = breadcrumb ? breadcrumb.clickable : true;

          return (
            <li className="flex" key={to}>
              <div className="flex items-center py-1">
                <svg
                  aria-hidden="true"
                  className="h-full w-6 flex-shrink-0 text-gray-200"
                  fill="currentColor"
                  preserveAspectRatio="none"
                  viewBox="0 0 24 44"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                {isLast || !isClickable ? (
                  <span
                    aria-current="page"
                    className="ml-4 text-sm font-medium text-gray-500"
                  >
                    {breadcrumbName}
                  </span>
                ) : (
                  <Link
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    to={to}
                  >
                    {breadcrumbName}
                  </Link>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
