import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { StochasticCustomer } from "../../../../../../api/fetchStochasticCustomers/types";

export function createCustomersColumns(): ColumnDef<StochasticCustomer>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "",
      accessorKey: "isNewCustomer",
      cell: ({ row }) => (row.original.isNewCustomer ? "🆕" : ""),
      enableSorting: false,
    },
    {
      header: "Has Installation",
      accessorKey: "hasInstallation",
      cell: ({ row }) => (row.original.hasInstallation ? "Yes" : "No"),
    },
    {
      header: "Has Subscription",
      accessorKey: "hasSubscription",
      cell: ({ row }) => (row.original.hasSubscription ? "Yes" : "No"),
    },
    {
      header: "Count Invoices",
      accessorKey: "countInvoices",
    },
    {
      header: "Balance Total",
      accessorKey: "balanceTotal",
    },
    {
      header: "Invoice Total",
      accessorKey: "invoiceTotal",
    },
    {
      header: "Lifetime Value",
      accessorKey: "lifetimeValue",
    },
    {
      header: "First Invoiced At",
      accessorKey: "firstInvoicedAt",
      cell: ({ row }) =>
        row.original.firstInvoicedAt
          ? new Date(row.original.firstInvoicedAt).toLocaleDateString()
          : "N/A",
    },
    {
      header: "Last Invoiced At",
      accessorKey: "lastInvoicedAt",
      cell: ({ row }) =>
        row.original.lastInvoicedAt
          ? new Date(row.original.lastInvoicedAt).toLocaleDateString()
          : "N/A",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div>
          <button onClick={() => alert(`Customer ID: ${row.original.id}`)}>
            View
          </button>
        </div>
      ),
      enableSorting: false,
    },
  ];
}
