import React from "react";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import { coachingDashboardMockData } from "../../data/CoachingDashboardMockdata";
import { useTheme } from "../../../../../../context/ThemeContext";
import CoachCard from "../CoachCard/CoachCard";

const CoachingDashboard: React.FC = () => {
  const { theme } = useTheme();
  const mockData = coachingDashboardMockData;

  return (
    <MainPageWrapper error={null} loading={false} title="Coaches Dashboard">
      <div
        className={`min-h-screen p-2 md:p-4 ${theme === "dark" ? "bg-secondary-dark" : "bg-gray-50"}`}
      >
        <div className="flex flex-col sm:flex-row justify-between mb-4 gap-2">
          <h1
            className={`text-xl md:text-2xl font-bold ${theme === "dark" ? "text-white" : "text-gray-900"}`}
          >
            Coaches dashboard
          </h1>
          <div className="flex gap-2"></div>
        </div>

        {mockData.map((coach, index) => (
          <CoachCard coach={coach} key={index} />
        ))}
      </div>
    </MainPageWrapper>
  );
};

export default CoachingDashboard;
